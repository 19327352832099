import React from "react";
import Faq from "../components/Faq";
import Layout from "components/Layout";
import "styles/pages/page-faq.scss";
import SubpageHeader from "../components/SubpageHeader";
import Seo from "components/Seo";

const FAQ = ({ pageContext }) => {
  const faq = pageContext.pageContent;

  const faqItems = faq.faq.map((item, index) => {
    return {
      key: index,
      title: item.question,
      desc: item.answer,
    };
  });
  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title={pageContext.title}
        image={require("assets/images/faq-bg.jpg").default}
      />
      <section className="section-faq">
        <div className="container">
          <Faq items={faqItems} visibleOnStart={false}></Faq>
        </div>
      </section>
    </Layout>
  );
};

export default FAQ;
